<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Applicant for Home Care Aide Registration
              </b-card-title>

              Please type or print clearly. Please ensure that you include a check or money order in the amount of $35.00, payable to the California Department of Social Services, and complete the LiveScan form (LIC 9163) to submit fingerprints. Mail this completed two-page application, the complete Criminal Record Statement (LIC 508), and a $35.00 check or money order to: The California Department of Social Services, Home Care Services Bureau, 744 P Street, MS 9-14-90, Sacramento, CA 95814. <strong>Per Health and Safety Code Section 1796.48, the Home Care Aide application fee is nonrefundable.</strong>
              <br><br>
              <strong>If any of the following apply, then you are not eligible for Home Care Aide registration at this time. Please note, if you continue with the application process, your application will be withdrawn and your fee will be forfeited.</strong>
              <ul>
                <li>
                  You had an application for a license, TrustLine registration, foster care certificate of approval, administrator certification, or home care aide registration denied within the past year;
                </li>
                <li>
                  You had a license, TrustLine registration, foster care certificate of approval, administrator certification, or home care aide registration revoked or rescinded within the past two years;
                </li>
                <li>
                  You had a criminal record exemption denied within the past two years; and/or
                </li>
                <li>
                  You were excluded from all licensed facilities, certified family homes, resource family homes, and/or home care organizations and have not successfully petitioned for reinstatement.
                </li>
              </ul>
              <validation-observer ref="simpleRules">
                <b-form>

                  <input
                    v-model="form.id"
                    type="hidden"
                  >

                  <input
                    v-model="form.cid"
                    type="hidden"
                  >

                  <div
                    class="mb-1"
                  ><strong>NAME</strong></div>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Last Name"
                        label-for="last-name"
                      >
                        <label
                          class="sr-only"
                          for="last-name"
                        >
                          * Last Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Last Name"
                          rules="required"
                        >
                          <b-form-input
                            id="last-name"
                            v-model="lastname"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Last Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* First Name"
                        label-for="first-name"
                      >
                        <label
                          class="sr-only"
                          for="first-name"
                        >
                          * First Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="First Name"
                          rules="required"
                        >
                          <b-form-input
                            id="first-name"
                            v-model="firstname"
                            :state="errors.length > 0 ? false : null"
                            placeholder="First Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Middle Name"
                        label-for="middle-name"
                      >
                        <label
                          class="sr-only"
                          for="middle-name"
                        >
                          Middle Name
                        </label>
                        <b-form-input
                          id="middle-name"
                          v-model="middlename"
                          placeholder="Middle Name"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* List all other names you have ever used, such as Maiden or Aliases (AKAs)"
                        label-for="aka"
                      >
                        <label
                          class="sr-only"
                          for="aka"
                        >
                          * List all other names you have ever used, such as Maiden or Aliases (AKAs)
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="List all other names you have ever used, such as Maiden or Aliases (AKAs)"
                          rules="required"
                        >
                          <b-form-input
                            id="aka"
                            v-model="aka"
                            :state="errors.length > 0 ? false : null"
                            placeholder="AKAs"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <div
                    class="mb-1 mt-1"
                  ><strong>RESIDENCE ADDRESS</strong></div>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Street Address"
                        label-for="street-address"
                      >
                        <label
                          class="sr-only"
                          for="street-address"
                        >
                          * Street Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Street Address"
                          rules="required"
                        >
                          <b-form-input
                            id="street-address"
                            v-model="streetAddress"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Street Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Apt"
                        label-for="apt"
                      >
                        <label
                          class="sr-only"
                          for="apt"
                        >
                          * Apt
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Apt"
                          rules="required"
                        >
                          <b-form-input
                            id="apt"
                            v-model="apt"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Apt"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* City"
                        label-for="city"
                      >
                        <label
                          class="sr-only"
                          for="city"
                        >
                          * City
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="City"
                          rules="required"
                        >
                          <b-form-input
                            id="city"
                            v-model="city"
                            :state="errors.length > 0 ? false : null"
                            placeholder="City"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* State"
                        label-for="state"
                      >
                        <label
                          class="sr-only"
                          for="state"
                        >
                          * State
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="State"
                          rules="required"
                        >
                          <b-form-input
                            id="state"
                            v-model="state"
                            :state="errors.length > 0 ? false : null"
                            placeholder="State"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Zip Code"
                        label-for="zip"
                      >
                        <label
                          class="sr-only"
                          for="zip"
                        >
                          * Zip Code
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Zip Code"
                          rules="required"
                        >
                          <b-form-input
                            id="zip"
                            v-model="zip"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Zip Code"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <div
                    class="mb-1 mt-1"
                  ><strong>MAILING ADDRESS (If different than above)</strong></div>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="Street Address"
                        label-for="street-address2"
                      >
                        <label
                          class="sr-only"
                          for="street-address2"
                        >
                          Street Address
                        </label>
                        <b-form-input
                          id="street-address2"
                          ref="mailStreetAddress"
                          v-model="form.mailStreetAddress"
                          placeholder="Street Address"
                          title="Street Address"
                        />
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Apt"
                        label-for="apt2"
                      >
                        <label
                          class="sr-only"
                          for="apt2"
                        >
                          Apt
                        </label>
                        <b-form-input
                          id="apt2"
                          ref="mailApt"
                          v-model="form.mailApt"
                          placeholder="Apt"
                          title="Apt"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="City"
                        label-for="city2"
                      >
                        <label
                          class="sr-only"
                          for="city2"
                        >
                          City
                        </label>
                        <b-form-input
                          id="city2"
                          ref="mailCity"
                          v-model="form.mailCity"
                          placeholder="City"
                          title="City"
                        />
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="State"
                        label-for="state2"
                      >
                        <label
                          class="sr-only"
                          for="state2"
                        >
                          State
                        </label>
                        <b-form-input
                          id="state2"
                          ref="mailState"
                          v-model="form.mailState"
                          placeholder="State"
                          title="State"
                        />
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Zip Code"
                        label-for="zip2"
                      >
                        <label
                          class="sr-only"
                          for="zip2"
                        >
                          Zip Code
                        </label>
                        <b-form-input
                          id="zip2"
                          ref="mailZip"
                          v-model="form.mailZip"
                          placeholder="Zip Code"
                          title="Zip Code"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="Email Address"
                        label-for="email"
                      >
                        <label
                          class="sr-only"
                          for="email"
                        >
                          Email Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Email Address"
                          rules="email"
                        >
                          <b-form-input
                            id="email"
                            v-model="emailAddress"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            placeholder="Email Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="Telephone Number"
                        label-for="tel-number"
                      >
                        <label
                          class="sr-only"
                          for="tel-number"
                        >
                          Telephone Number
                        </label>
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            US (+1)
                          </b-input-group-prepend>
                          <b-form-input
                            id="tel-number"
                            v-model="telNumber"
                            v-mask="['(###) ###-####']"
                            type="tel"
                            placeholder="Telephone Number"
                          />
                        </b-input-group>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <div
                    class="mb-1 mt-1"
                  ><strong>PERSONAL INFORMATION</strong></div>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="Social Security Number (Voluntary)"
                        label-for="ssn"
                      >
                        <label
                          class="sr-only"
                          for="ssn"
                        >
                          Social Security Number (Voluntary)
                        </label>
                        <b-form-input
                          id="ssn"
                          v-model="ssn"
                          v-mask="['###-##-####']"
                          placeholder="Social Security Number"
                        />
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date of Birth"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date of Birth"
                          label-for="date-birth"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-birth"
                          >
                            * Date of Birth
                          </label>
                          <flat-pickr
                            id="date-birth"
                            v-model="dateBirth"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <b-form-group
                        label="* Gender"
                        label-for="gender"
                      >
                        <label
                          class="sr-only"
                          for="gender"
                        >
                          * Gender
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Gender"
                          rules="required"
                        >
                          <b-form-input
                            id="gender"
                            v-model="gender"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Gender"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <div
                    class="mb-1 mt-1"
                  ><strong>IDENTIFICATION</strong></div>

                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group
                        label="* Identity Document Type"
                        label-for="id-type"
                      >
                        <label
                          class="sr-only"
                          for="id-type"
                        >
                          * Identity Document Type
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Identity Document Type"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="idType"
                            v-model="form.idType"
                            :options="['California Driver\'s License', 'California Identification Card', 'Alien Registration Card', 'Identification Card From Another State Agency']"
                            :state="errors.length > 0 ? false : null"
                            title="Identity Document Type"
                          />
                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="Issuing State"
                        label-for="issuing-state"
                      >
                        <label
                          class="sr-only"
                          for="issuing-state"
                        >
                          Issuing State
                        </label>
                        <b-form-input
                          id="issuing-state"
                          ref="issuingState"
                          v-model="form.issuingState"
                          placeholder="Issuing State"
                          title="Issuing State"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="6"
                    >

                      <b-form-group
                        label="* Document Number"
                        label-for="doc-number"
                      >
                        <label
                          class="sr-only"
                          for="doc-number"
                        >
                          * Document Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Document Number"
                          rules="required"
                        >
                          <b-form-input
                            id="doc-number"
                            ref="docNumber"
                            v-model="form.docNumber"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Document Number"
                            title="Document Number"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="Expiration Date"
                        label-for="exp-date"
                      >
                        <label
                          class="sr-only"
                          for="exp-date"
                        >
                          Expiration Date
                        </label>
                        <b-input-group class="flatpickr">
                          <flat-pickr
                            id="exp-date"
                            ref="expDate"
                            v-model="form.expDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Expiration Date"
                          />
                          <b-input-group-append>
                            <b-button
                              variant="outline-dark"
                              title="Clear"
                              class="brdr-gray px-1"
                              data-clear
                            >
                              <i class="fa fa-times">
                                <span
                                  aria-hidden="true"
                                  class="sr-only"
                                >
                                  Clear
                                </span>
                              </i>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <h4 class="text-center mt-2 mb-2">
                    Disclosure of Personal Information
                  </h4>

                  Assembly Bill 2455 (Statutes 2018, Chapter 917, Section 2) created a new law that affects all Registered Home Care Aides. This law requires CDSS to provide the name and telephone numbers of Registered Home Care Aides to labor organizations, when requested. This law also requires CDSS to create a process for Registered Home Care Aides to tell the Department not to release their information, or “opt-out” of the disclosure of their personal information.<br><br>Effective July 1, 2019, all individuals who become Registered Home Care Aides and all Registered Home Care Aides renewing their registration are subject to their names and telephone numbers being released to labor organizations. If you do not want your personal information released, please check the box below.
                  <hr
                    class="mb-3"
                  >
                  <b-row>
                    <b-col
                      cols="12"
                    >

                      <b-form-group>
                        <b-form-checkbox-group
                          ref="disclose"
                          v-model="form.disclose"
                          :options="['I do not want my personal information shared with labor organizations and wish to opt-out of this requirement.']"
                          title="Disclosure of Personal Information"
                        />
                      </b-form-group>

                    </b-col>
                  </b-row>
                  <hr
                    class="mt-2"
                  >

                  Please note the following:<br>
                  <ul>
                    <li>
                      You must be 18 years of age or older to apply to be listed on the Home Care Aide Registry.
                    </li>
                    <li>
                      Once you are registered, failure to notify the Home Care Services Bureau of a change of mailing address within ten (10) days will result in forfeiture of your registration.
                    </li>
                    <li>
                      You must list one of these four IDs: California Driver’s License; California ID card; Alien Registration Card; or a numbered, picture ID issued from a state other than California. If the application only has a Social Security Number without one of these four acceptable IDs, it will be returned.
                    </li>
                    <li>
                      Pursuant to the Federal Privacy Act (P.L. 93-579) and the Information Practices Act of 1977 (Civil Code Sections 1798 et seq.) notice is given for the request of your Social Security Number (SSN) on this form. The requested SSN is voluntary; however, failure to provide the SSN may delay the processing of this form and the criminal record check. The law requires that you complete a background check (Health and Safety Code Section 1796.24). The Department will create a file concerning your criminal background check that will contain certain documents, including information that you provide. You have a right to access certain records containing your personal information maintained by the Department (Civil Code Section 1798 et seq.). Under the California Public Records Act and the Freedom of Information Act, the Department may have to provide copies of some of the records in the file to members of the public who ask for them, including newspaper and television reporters.
                    </li>
                  </ul>

                  <div
                    class="mb-1"
                  ><strong>I DECLARE UNDER PENALTY OF PERJURY THAT THE STATEMENTS ON THIS FORM ARE CORRECT TO THE BEST OF MY KNOWLEDGE.</strong></div>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="8"
                    >

                      <b-form-group
                        label="* Employee Signature"
                        label-for="emp-signature"
                      >
                        <label
                          class="sr-only"
                          for="emp-signature"
                        >
                          * Employee Signature
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Employee Signature"
                          rules="required"
                        >
                          <vue-signature-pad
                            ref="empSignature"
                            v-model="form.empSignature"
                            :state="errors.length > 0 ? false : null"
                            width="100%"
                            height="150px"
                            :options="{ onBegin, onEnd }"
                            class="form-control"
                            :class="{ 'is-invalid' : errors.length > 0 && form.empSignature === '' }"
                            title="Employee Signature"
                          />

                          <b-row>

                            <b-col>
                              <small
                                v-if="form.empSignature === ''"
                                class="text-danger"
                              >
                                {{ errors[0] }}
                              </small>
                            </b-col>

                            <b-col
                              class="text-right mt-1"
                            >
                              <!-- Button Undo -->
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                variant="outline-dark"
                                class="mr-1"
                                @click="undoSignature"
                              >
                                Undo
                              </b-button>

                              <!-- Button Clear -->
                              <b-button
                                variant="outline-danger"
                                @click="clearSignature"
                              >
                                Clear
                              </b-button>
                            </b-col>
                          </b-row>

                        </validation-provider>
                      </b-form-group>

                    </b-col>

                    <b-col
                      cols="12"
                      lg="4"
                    >

                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="date-signed"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="date-signed"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="date-signed"
                            ref="dateSigned"
                            v-model="form.dateSigned"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>

                    </b-col>
                  </b-row>

                  <div
                    class="mb-1"
                  ><strong>Federal law (at Title 5 United States Code Section 552a Note) states that: Any federal, state, or local government agency which requests an individual to disclose his social security account number shall inform that individual whether that disclosure is mandatory or voluntary, by what statutory or other authority such number is solicited, and what uses will be made of it.</strong></div>

                  <!-- Button Submit -->
                  <b-button
                    variant="primary"
                    type="submit"
                    class="mr-1 d-none"
                    @click.prevent="validationForm"
                  >
                    Submit
                  </b-button>

                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm()"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form14b', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form15', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroupPrepend, BInputGroupAppend, BInputGroup, VBToggle, BFormCheckboxGroup, BFormRadioGroup,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { VueSignaturePad } from 'vue-signature-pad'
import { mask } from 'vue-the-mask'
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    BFormCheckboxGroup,
    BFormRadioGroup,

    vSelect,
    flatPickr,
    VueSignaturePad,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const empSignature = ref(null)

    const lastname = ref('')
    const firstname = ref('')
    const middlename = ref('')
    const aka = ref('')
    const streetAddress = ref('')
    const apt = ref('')
    const city = ref('')
    const state = ref('')
    const zip = ref('')
    const emailAddress = ref('')
    const telNumber = ref('')
    const ssn = ref('')
    const dateBirth = ref('')
    const gender = ref('')

    const input = ref(true)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          lastname.value = jsonObjectLiterals.data.lastName
          firstname.value = jsonObjectLiterals.data.firstName
          middlename.value = jsonObjectLiterals.data.middleName
          // eslint-disable-next-line
          aka.value = jsonObjectLiterals.data.akaFirstName + ' ' + jsonObjectLiterals.data.akaLastName
          streetAddress.value = jsonObjectLiterals.data.street
          apt.value = jsonObjectLiterals.data.aptunit
          city.value = jsonObjectLiterals.data.cityTown
          state.value = jsonObjectLiterals.data.state
          zip.value = jsonObjectLiterals.data.zipCode
          emailAddress.value = jsonObjectLiterals.data.emailAddress
          telNumber.value = jsonObjectLiterals.data.phoneNumber
          ssn.value = jsonObjectLiterals.data.ssn
          dateBirth.value = jsonObjectLiterals.data.dateOfBirth
          gender.value = jsonObjectLiterals.data.sex

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm14c', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                if (jsonObjectLiterals.data.empSignature) {
                  empSignature.value.fromDataURL(jsonObjectLiterals.data.empSignature)
                }

                if (form.value.id) {
                  form.value.form = 'folder4_form1c'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      wrap: true,
      dateFormat: 'Y-m-d',
    }

    const vueSignaturePadOptions = {
      penColor: 'rgb(0, 0, 0)',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      empSignature,

      lastname,
      firstname,
      middlename,
      aka,
      streetAddress,
      apt,
      city,
      state,
      zip,
      emailAddress,
      telNumber,
      ssn,
      dateBirth,
      gender,

      input,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      flatPickrConfig,
      vueSignaturePadOptions,

      sock,
      sockId,
    }
  },
  data() {
    return {
      id: this.$route.query.id,
      action: this.$route.params.action,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
      email,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    clearSignature() {
      this.$refs.empSignature.clearSignature()
    },
    undoSignature() {
      this.$refs.empSignature.undoSignature()
    },
    onBegin() {
      // Do something
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.empSignature.saveSignature()
      this.form.empSignature = !isEmpty ? data : ''
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          if (jsonObjectLiterals.data.empSignature) {
            self.$refs.empSignature.clearSignature()
            self.$refs.empSignature.fromDataURL(jsonObjectLiterals.data.empSignature)
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      /* const { isEmpty, data } = this.$refs.empSignature.saveSignature()
      this.form.empSignature = !isEmpty ? data : '' */

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm14c' : 'app-hr/updateForm14c', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved successfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
.min-width-300px
{
  min-width: 300px;
}

.min-width-140px
{
  min-width: 140px;
}

.mt-1rem {
  margin-top: 1rem;
}
</style>

<style scoped>
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
